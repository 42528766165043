import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint8 = () => (
  <Layout>
    <Header /> 

    <header className="masthead8">

      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #8</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            <br></br>
          <br></br>
            Your getting closer,
            <br></br>
            but not quite there.
            <br></br>
            <br></br>
            Head outdoors and
            <br></br>
            pull up a chair.
            <br></br>
            <br></br>
            Order a drink,
            <br></br>
            take in the view.
            <br></br>
            <br></br>
            Reflect in the place
            <br></br>
            you once said "I do".
            <br></br>
            <br></br>
          </h2>
          <br></br>
          <br></br>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 150' East</h3>
          <br></br>
          <br></br>
          <a href="/clue8" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint8;
